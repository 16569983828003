import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import { RegionContainer } from '../styles/Utility.styles'

export const GridHeaderContainer = styled(RegionContainer)`
  margin-top: unset;
  padding-bottom: 3.2rem;

  h2 {
    grid-column: 2 / 12;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.35;
    color: var(--content-background-dark-blue);
    margin-bottom: 14px;

    @media ${DEVICE.laptopS} {
      font-size: 2.4rem;
      line-height: 1.375;
      margin-bottom: 12px;
    }
  }
`

export const PaginationContainer = styled(RegionContainer)`
  padding-bottom: 4.8rem;
`

export const FilterRowContainer = styled.div`
  margin-top: -2.4rem;
  margin-bottom: 2.4rem;
`

export const FilterIndicatorBarContainer = styled(RegionContainer)`
  margin-top: -2.4rem;
  margin-bottom: 2.4rem;
`
